import { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import { createTheme, ThemeProvider } from "@mui/material/styles";


const darkTheme = createTheme({
    palette: {
        mode:'dark'
    }
})

export const TableBasic = () => {

    const [clientes, setProducts] = useState( [] );
const [totalRows, setTotalRows] = useState(0);

//2 - fcion para mostrar los datos con axios
const Search = '';
const Index = 1;
const PageSize = 10;


const getData = async (Search = '', Index = 1, PageSize = 10) => {
    // const endpoint = `https://jsonplaceholder.typicode.com/users`

    const endpoint = `https://backend-php.gianpierovasquez.cloud/conexion.php?Search=${Search}&Index=${Index}&PageSize=${PageSize}`;

    try {
        const response = await fetch(endpoint);
        if (!response.ok) {
            throw new Error('Error al obtener los datos'); // Manejar errores de solicitud
        }
        
        const data = await response.json();
        if (Array.isArray(data) && data.length > 0) {
            // console.log(data);
            setProducts(data); 
            setTotalRows(response.headers.get('x-total-count') || 0);
        }else {
            setProducts([]); // Si no hay datos, actualiza el estado con un arreglo vacío
            setTotalRows(0);
        }
    } catch (error) {
        console.log("Error en la conexión", error);
    }
}


useEffect( ()=>{
    getData();
}, []);

//3 - Definimos las columnasss
const columns = [
    {
        name: "Empresa",
        label: "Empresa"
    },
    {
        name: "Código",
        label: "ID"
    },
    {
        name: "TipoUA",
        label: "TipoUA"
    },
    {
        name: "Generación",
        label: "Generación"
    },
    {
        name: "Marca",
        label: "Marca"
    },
    {
        name: "Nombre",
        label: "Asesor"
    }

];
const updateTable = (searchText, page, rowsPerPage) => {
    const adjustedSearchText = searchText || ''; // Si searchText es null o undefined, establece una cadena vacía
    const adjustedIndex = page + 1; // Ajustar el índice de la página sumándole 1
    getData(adjustedSearchText, adjustedIndex, rowsPerPage);
}
const options = {
    filter: true,
    search: true,
    pagination: true,
    rowsPerPage: 10,
    rowsPerPageOptions: [5, 10, 15],
    serverSide: true, // Indica que se manejarán los datos en el servidor
    count: totalRows, // El total de filas a mostrar
    onTableChange: (action, tableState) => {
        switch (action) {
            case 'changePage':
                updateTable(tableState.searchText, tableState.page, tableState.rowsPerPage);
                break;
            case 'search':
                updateTable(tableState.searchText, tableState.page, tableState.rowsPerPage);
                break;
            default:
                break;
        }
    }
  };
//4 - renderizamos la datatable
        return (
            <ThemeProvider theme={darkTheme}>
                <MUIDataTable 
                    title={"Buscar Empresa"}
                    data={clientes}
                    columns={columns}
                    options={options}
                />
            </ThemeProvider>
            
        )

}