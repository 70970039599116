import React from 'react';
import './App.css';
import { TableBasic } from './components/DataTable/dataTable';

// import SimpleDataTable from './components/simpleDataTables';
// import MaterialTable from 'material-table';

function App() {
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
   <div className='App'>
    <header className='App-header'>
    <h2>Buscar por Empresa ( Cliente )</h2>
    <TableBasic/>
    </header>
   </div>
  );
}

export default App;
